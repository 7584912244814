$(document).ready(function(){
	

	$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
		event.preventDefault();
		$(this).ekkoLightbox();
	});

	$('.scrollTop').click(function(){
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, 600);
		return false;
	});

	
	$('.carousel').carousel('pause');
	

	//main_carousel
		var main_carousel_t;
		var main_carousel_start = $('#main_carousel').find('.active').attr('data-interval');
		if (main_carousel_start == 0) main_carousel_start = 5000;

		main_carousel_t = setTimeout(function () {
			$('#main_carousel').carousel('next')
		}, main_carousel_start);

		$('#main_carousel').on('slid.bs.carousel', function () {

			clearTimeout(main_carousel_t);
			var duration = $('#main_carousel').find('.active').attr('data-interval');
			if (duration == 0 ) duration = 5000;

		//$('.carousel').carousel('pause');
		main_carousel_t = setTimeout("$('#main_carousel').carousel('next');", duration);
		})

		$('.carousel-control.right').on('click', function () {
			clearTimeout(main_carousel_t);
		});

		$('.carousel-control.left').on('click', function () {
			clearTimeout(main_carousel_t);
		});

	//second_carousel
		var second_carousel_t;
		var second_carousel_start = $('#second_carousel').find('.active').attr('data-interval');
		if (second_carousel_start == 0) second_carousel_start = 5000;

		second_carousel_t = setTimeout(function () {
			$('#second_carousel').carousel('next')
		}, second_carousel_start);

		$('#second_carousel').on('slid.bs.carousel', function () {

			clearTimeout(second_carousel_t);
			var duration = $('#second_carousel').find('.active').attr('data-interval');
			if (duration == 0 ) duration = 5000;

		//$('.carousel').carousel('pause');
		second_carousel_t = setTimeout("$('#second_carousel').carousel('next');", duration);
		})

		$('.carousel-control.right').on('click', function () {
			clearTimeout(second_carousel_t);
		});

		$('.carousel-control.left').on('click', function () {
			clearTimeout(second_carousel_t);
		});

});

