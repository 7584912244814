$(document).ready(function(){
	$("#contact_form").validate({
		errorElement: "em",
		invalidHandler: function(e, validator) {
			var errors = validator.numberOfInvalids();
			if (errors) {
				$(".contact_form_message .info").slideUp();
				$(".contact_form_message .error").html(contact_form_ajax_validation_error).slideDown();
			} else {
				$(".contact_form_message .error").slideUp();
			}
		},
		errorPlacement: function(error, element){
			$(element).parent().addClass('has-error has-feedback');
			error.insertAfter(element);
		},
		success: function(error, element) {
			$(element).parent().removeClass('has-error has-feedback');
			$(element).parent().addClass('has-success has-feedback');

		},
		submitHandler: function(form) {
			$(".contact_form_message .info").slideDown();
			$(".contact_form_message .error").slideUp();

			var frm_serialize = $('#contact_form').serialize();
			$.ajax({
				type: "POST",
				url: contact_form_ajax_url,
				timeout: 30000,
				data: frm_serialize,
				dataType: "json",
				success: function (data) {
					$(".contact_form_message .info").slideUp();
					if (data.error) {
						$(".contact_form_message .error").html(data.message).slideDown();
					} else {
						$(".contact_form_message .success").html(data.message).slideDown();
						$("#contact_form").slideUp();
					}
				},
				error: function(a,b,c) {
					$(".contact_form_message .info").slideUp();
					$(".contact_form_message .error").html(contact_form_ajax_fatal_error).slideDown();
				}
			});
		}
	});
});